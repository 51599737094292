<template>
  <div>
    <b-overlay
        :show="!pageLoaded"
    >
  <b-card
      v-if="pageLoaded && userData.practiceId === 0 && userData.role !== 'admin'"
      no-body
      class="mb-0"
  >

    <div class="m-2 pb-4 text-center">
      <img src="@/assets/images/illustration/marketing.svg">
      <h3 class="mt-3">You don't have a practice yet. Would you like to create one?</h3>
      <b-button href="/practice/edit">Create Practice</b-button>
    </div>
  </b-card>

  <b-card
      v-if="userData.practiceId !== 0 || userData.role === 'admin'"
      class="practice-profile-wrapper"
  >
    <b-row v-if="pageLoaded">
      <b-col
          cols="4" class="mt-50">
        <h2>Risk: {{ practiceRisk }}
          <b-badge v-if="practice.thresholdRating === 'low'" variant="success">Low Risk</b-badge>
          <b-badge v-if="practice.thresholdRating === 'medium'" variant="warning">Medium Risk</b-badge>
          <b-badge v-if="practice.thresholdRating === 'high'" variant="danger">High Risk</b-badge>
          <b-badge v-if="practice.thresholdRating === 'very_high'" class="very-high-risk">Very High Risk</b-badge>
        </h2>
      </b-col>
      <b-col
          cols="4" class="mt-50 text-center">
        <h2 v-if="practice.isComplete"><feather-icon icon="CheckIcon" size="28" class="text-success" /> Complete</h2>
        <h2 v-if="!practice.isComplete"><feather-icon icon="AlertTriangleIcon" size="28" class="text-warning" /> Incomplete</h2>
      </b-col>
      <b-col
          cols="4"
          class="mt-50 text-right"
      >
        <b-button
            :disabled="downloadingFRA"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="success"
            class="mr-1"
            @click="downloadFRA(practice)"
        >
          <span v-if="!downloadingFRA">Download FRA</span>
          <span v-if="downloadingFRA"><b-spinner small/> Loading...</span>
        </b-button>
        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-1" href="/practice/edit">Edit</b-button>
      </b-col>
    </b-row>

    <!--form -->
    <b-form class="mt-2">
      <b-row>
        <b-col md="12">
          <h3>General Information</h3>
        </b-col>
        <b-col md="6">
          <p class="mb-0"><strong>Name:</strong> {{ practice.name }}</p>
        </b-col>
        <b-col md="6">
          <p class="mb-0"><strong>Practice Structure:</strong> {{ practice.structure }}</p>
          <p v-if="practice.structure && (practice.structure !== 'Sole Trader' && practice.structure !== 'Partnership')"><strong>Registration Number:</strong> {{ practice.registrationNumber }}</p>
        </b-col>
        <b-col md="6">
          <p class="mb-0"><strong>Email:</strong> {{ practice.email }}</p>
        </b-col>
        <b-col md="6">
          <p class="mb-0"><strong>Phone Number:</strong>
            <span v-if="practice.phone"> (+44) {{ practice.phone }}</span>
            <span v-if="!practice.phone"> Unavailable</span>
          </p>
        </b-col>
        <b-col md="6" class="mt-3">
          <h3>Principal Business Address</h3>
          <p class="mb-0">{{ practice.principleStreetAddress }}</p>
          <p>{{ practice.principleCity }}, {{ practice.principlePostalCode }}</p>

        </b-col>
        <b-col md="6" class="mt-3">
          <h3>Registered Business Address</h3>
          <p class="mb-0">{{ practice.registeredStreetAddress }}</p>
          <p>{{ practice.registeredCity }}, {{ practice.registeredPostalCode }}</p>
        </b-col>
        <b-col md="12">
          <h3>Totals</h3>
        </b-col>
        <b-col md="6">
          <p class="mb-0"><strong>Total Beneficial Owners: </strong>{{ practice.totalBeneficialOwners }}</p>
        </b-col>
        <b-col md="6">
          <p class="mb-0"><strong>Total Staffs: </strong>{{ practice.totalStaff }}</p>
        </b-col>
        <b-col md="6">
          <p class="mb-0"><strong>Total Subcontractors: </strong>{{ practice.totalSubcontractors }}</p>
        </b-col>
        <b-col md="6">
          <p class="mb-0"><strong>Total Clients: </strong>{{ practice.totalClients }}</p>
        </b-col>
        <b-col md="6" v-if="userData && userData.role === 'admin'">
          <p class="mb-0"><strong>Review Tasks: </strong><b-link :to="{name: 'iab-tasks-list',query: {practice: practice.id}}">{{ practice.usertasks }}</b-link></p>
        </b-col>
        <b-col md="12" v-for="(category,index) in riskQuestionCategories" :key="index" class="mt-2 mb-2">
          <h3>{{ category }}</h3>
          <table class="table table-striped table-bordered">
            <thead>
            <tr>
              <th>Question</th>
              <th class="text-center">Answer</th>
              <th class="text-center">File</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(riskQuestion,index) in riskQuestions" :key="index" v-if="riskQuestion.category === category">
              <td width="70%">{{ riskQuestion.question }}</td>
              <td class="text-center" width="15%">
                <div v-if="form[riskQuestion.questionLabel]">
                  <div v-if="form[riskQuestion.questionLabel].questionType === 'select'">
                    {{ form[riskQuestion.questionLabel].answer }}
                  </div>
                  <div v-if="form[riskQuestion.questionLabel].questionType === 'text'">
                    {{ form[riskQuestion.questionLabel].input }}
                  </div>
                  <div v-if="form[riskQuestion.questionLabel].questionType === 'checkbox' || form[riskQuestion.questionLabel].questionType === 'multi_select'">
                    <p v-for="selection in form[riskQuestion.questionLabel].selections">{{ selection }}</p>
                  </div>
                </div>
              </td>
              <td class="text-center" width="15%">
                <div v-if="form[riskQuestion.questionLabel]">
                  <feather-icon v-if="form[riskQuestion.questionLabel].fileUrl" class="mr-1 cursor-pointer" icon="FileTextIcon" size="20" @click="downloadFile(form[riskQuestion.questionLabel].fileUrl)" />
                </div>
              </td>
            </tr>
            </tbody>
          </table>
<!--            <p v-if="form[riskQuestion.questionLabel].file">[ File link ]</p>-->
        </b-col>
      </b-row>
    </b-form>
    <!--/ form-->
  </b-card>
    </b-overlay>
  </div>
</template>

<script>
import {
  BCard,
  BMedia,
  BAvatar,
  BCardText,
  BMediaAside,
  BMediaBody,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BLink,
  BButton,
  BBadge,
  BInputGroupPrepend,
  BInputGroup,
  BFormCheckbox,
  BSpinner,
  BOverlay,
  VBTooltip
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import Cleave from 'vue-cleave-component'
import 'cleave.js/dist/addons/cleave-phone.gb'
import riskService from '@core/services/risk/useRisk'
import practiceService from '@core/services/practice/usePractice'
import helperMixin from "@core/mixins/helpers";
import {$themeConfig} from "@themeConfig";

export default {
  components: {
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BMediaAside,
    BMediaBody,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BBadge,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BFormFile,
    BInputGroupPrepend,
    BFormCheckbox,
    BSpinner,
    BOverlay,
    VBTooltip,
    vSelect,
    Cleave,
  },
  mixins: [helperMixin],
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  mounted() {
    this.userData = JSON.parse(localStorage.getItem('userData'));

    if (this.userData.practiceId || this.userData.role === "admin") {
      this.getRiskQuestions();
    }else{
      this.pageLoaded = true;
    }
  },
  data() {
    return {
      userData: {},
      practice: {
        id: 0,
        name: "",
        structure: "",
        registrationNumber: "",
        email: "",
        phone: "",
        principleStreetAddress: "",
        principleCity: "",
        principlePostalCode: "",
        registeredStreetAddress: "",
        registeredCity: "",
        registeredPostalCode: "",
        answers: [],
        clientTotal: 0,
        thresholdRating: '',
        isComplete: false,
      },
      form: {},
      riskQuestionCategories: [],
      practiceRisk: 0,
      practiceExists: false,
      riskQuestions: [],
      answers: [],
      pageLoaded: false,
      downloadingFRA: false
    }
  },
  methods: {
    getPractice() {
      let practiceId = 0;

      if ( this.$route.params.id ){
        practiceId = this.$route.params.id;
      }else{
        practiceId = this.userData.practiceId;
      }

      if ( this.userData.practiceId !== practiceId && this.userData.role !== 'admin' ){
        window.location.href = 0;
      }

      practiceService.getPractice({
        id: practiceId
      }).then(response => {
        if ( response.data ){
          this.practiceExists = true;
          this.practice = response.data;
          let that = this;

          if ( this.practice.answers.length > 0 ){
            this.practice.answers.forEach(function (answer) {
              that.$set(that.form, answer.questionLabel, answer)
            });
          }

          this.recalculateRisk();
          this.pageLoaded = true;
        }
      }).catch(error => {
        if(error.response.status == 403){
          window.location = $themeConfig.app.suspendedAccountRedirect;
        }
      });
    },
    getRiskQuestions() {
      riskService.getRiskQuestions({
        'system_form_id': 1
      }).then(response => {
        // let practice = this.practice;
        let that = this;
        this.riskQuestions = response.data.questions;
        this.riskQuestionCategories = response.data.categories;
        this.riskQuestions.forEach(function (question) {
          that.$set(that.form, question.questionLabel, null)
        });

        this.getPractice();
      }).catch(error => {
        if(error.response.status == 403){
          window.location = $themeConfig.app.suspendedAccountRedirect;
        }
      });

    },
    downloadFile(url){
      window.open(url,'_blank');
    },
    downloadFRA(practice){
      this.downloadingFRA = true;
      practiceService.download({id:practice.id,responseType: "arraybuffer"}).then(response => {
        if(response.status == 200){
          const url = URL.createObjectURL(new Blob([response.data],{type:'application/pdf'}));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute("download","FRA Document.pdf");
          document.body.appendChild(link);
          link.click();
          window.URL.revokeObjectURL(url);
          link.remove();
        }
        this.downloadingFRA = false;
      }).catch(error => {
        if(error.response.status == 403){
          window.location = $themeConfig.app.suspendedAccountRedirect;
        }
        console.log(error);
      });
    },
    recalculateRisk(){
      this.practiceRisk = riskService.recalculateRisk(this.practice.answers);
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
